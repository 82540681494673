







import Vue from 'vue'
import templatePage from '@/components/importfile/templatePage.vue'

export default Vue.extend({
  components: {
    templatePage,
  },
  data() {
    return {
      id: '',
    }
  },
  mounted() {
    this.id = this.$route.params.id
  },
})
